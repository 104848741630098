import { render, staticRenderFns } from "./RadioButton.vue?vue&type=template&id=61038b9f&scoped=true&lang=pug&"
import script from "./RadioButton.vue?vue&type=script&lang=js&"
export * from "./RadioButton.vue?vue&type=script&lang=js&"
import style0 from "./RadioButton.vue?vue&type=style&index=0&id=61038b9f&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61038b9f",
  null
  
)

export default component.exports