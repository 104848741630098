<template lang="pug">
  .sale-settings-radio-button(@click="handleClick")
    .outer-circle(:class="{ 'active': value }")
      .inner-circle(:class="color")
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      color: String
    },

    methods: {
      handleClick() {
        this.$emit("change", this.color)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  $outer-circle-side: 30px
  $inner-circle-side: 24px

  .sale-settings-radio-button
    .outer-circle
      align-items: center
      background-color: $default-white
      border-radius: $outer-circle-side
      cursor: pointer
      display: flex
      height: $outer-circle-side
      justify-content: center
      width: $outer-circle-side

      &.active
        border: 1px solid $default-black

      .inner-circle
        background-color: $default-purple
        z-index: 1000001
        border-radius: $inner-circle-side
        height: $inner-circle-side
        width: $inner-circle-side

        &.red
          background-color: $default-red
        &.blue
          background-color: $default-blue-dark
        &.yellow
          background-color: $default-yellow-light
        &.pink
          background-color: $default-red-medium
        &.green
          background-color: $default-green
</style>
